<template>
<div>
    <!-- <h4>
        z-Test: Stichprobenmittelwert &mu;₁ vs Populationsmittelwert &mu;₀
    </h4> -->
    <b-form @submit="onSubmit">
    <b-card
        header="Testdaten"
        class="mb-2"
    >
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Hypothesen
            </b-card-sub-title>
            <b-form-row>
                <b-col cols = "4"> Nullhypothese       </b-col>
                <b-col cols = "8"> H₀ : &mu;₁ = &mu;₂  </b-col>
            </b-form-row>
            <b-form-row>
                <b-col cols = "4">
                    <label for="h1">Alternativhypothese</label>
                </b-col>
                <b-col cols = "8">
                    <b-form-select id="h1" v-model="altHyp" class="mb-2 mr-sm-2 mb-sm-0">
                        <option value="Hneq">H₁ : &mu;₁ &ne; &mu;₂ <span>(</span>beidseitig) </option>
                        <option value="Hlt">H₁ : &mu;₁ &lt; &mu;₂ 	<span>(</span>einseitig) </option>
                        <option value="Hgt">H₁ : &mu;₁ &gt; &mu;₂ <span>(</span>einseitig) </option>
                    </b-form-select>
                </b-col>
            </b-form-row>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Daten
            </b-card-sub-title>

            <b-form-group 
                        id="sample1-data" 
                        label="Stichprobe 1:" 
                        description="in der Stichprobe erhobene Daten"
            >
                <label for="s1-mean">Mittelwert x&#x0304;₁
                    <b-form-input type="number" step="any" id="s1-mean" v-model="s1mean"></b-form-input>
                </label>
                <label for="s1-psdev">Standardabweichung &sigma;₁
                    <b-form-input type="number" step="any" id="s1-psdev" v-model="s1psdev"></b-form-input>
                </label>
                <label for="s1-n">Umfang n₁
                    <b-form-input type="number" step="any" id="s1-n" v-model="s1n"></b-form-input>
                </label>
            </b-form-group>

            <b-form-group 
                        id="sample2-data" 
                        label="Stichprobe 2:" 
                        description="in der Stichprobe erhobene Daten"
            >
                <label for="s2-mean">Mittelwert x&#x0304;₂
                    <b-form-input type="number" step="any" id="s2-mean" v-model="s2mean"></b-form-input>
                </label>
                <label for="s2-psdev">Standardabweichung &sigma;₂
                    <b-form-input type="number" step="any" id="s2-psdev" v-model="s2psdev"></b-form-input>
                </label>
                <label for="s2-n">Umfang n₂
                    <b-form-input type="number" step="any" id="s2-n" v-model="s2n"></b-form-input>
                </label>
            </b-form-group>

        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Testen
            </b-card-sub-title>
            <b-form-group
                id="test-data"
            >
                <label for="alpha"> Signifikanzniveau &alpha; 
                    <b-form-input type="number" step="any" id="alpha" v-model="alpha">&alpha;</b-form-input>
                </label>
                <b-button type="submit" variant="primary">Test ausführen</b-button>             
            </b-form-group>
        </b-card-body>    
    </b-card>
    </b-form>
    <b-card class="mt-3" header="Ergebnis des Tests" v-if="executed">
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Hypothesen
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item :variant="executed.H0variant">
                    H₀ : &mu;₁ = &mu;₀
                </b-list-group-item>
                <b-list-group-item :variant="executed.H1variant">
                    <span v-html="executed.H1"></span> <!-- Mustache geht nicht, weil HTML -->
                </b-list-group-item>
            </b-list-group>
            <b-card-text align-="center">
                <strong>
                Entscheidung für H{{executed.Entscheidung}}
                </strong>
            </b-card-text>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Ausgangslage
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item > Stichprobe 1 Mittelwert: {{ executed['Stichprobe 1 Mittelwert']}}</b-list-group-item>
                <b-list-group-item > Stichprobe 1 StdAbw: {{ executed['Stichprobe 1 StdAbw']}}</b-list-group-item>
                <b-list-group-item > Stichprobe 1 Umfang: {{ executed['Stichprobe 1 Umfang']}}</b-list-group-item>
                <b-list-group-item > Population 2 Mittelwert: {{ executed['Stichprobe 2 Mittelwert']}}</b-list-group-item>
                <b-list-group-item > Stichprobe 2 StdAbw: {{ executed['Stichprobe 2 StdAbw']}}</b-list-group-item>
                <b-list-group-item > Population 2 Umfang: {{ executed['Stichprobe 2 Umfang']}}</b-list-group-item>
                <b-list-group-item > Differenz Mittelwerte: {{ executed['Stichprobe 2 Mittelwert']-executed['Stichprobe 1 Mittelwert']}}</b-list-group-item>
                <b-list-group-item > Signifikanzniveau: {{ executed.alpha }} </b-list-group-item>
            </b-list-group>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Testergebnis
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item > Standardfehler: {{ executed['Standardfehler']}}</b-list-group-item>
                <b-list-group-item > kritischer z-Wert: {{ executed['kritischer z-Wert']}}</b-list-group-item>
                <b-list-group-item > kritische Differenz: {{ executed['kritische Differenz']}}</b-list-group-item>
                <b-list-group-item > Teststatistik (z-Wert): {{ executed['Teststatistik'] }} </b-list-group-item>
                <b-list-group-item > p-Value: {{ executed['p-Value']}}</b-list-group-item>
                <b-list-group-item > Entscheidung: H{{ executed['Entscheidung']}}</b-list-group-item>
            </b-list-group>
        </b-card-body>

    </b-card>
</div>
</template>

<script>

// import { VueMathjax } from 'vue-mathjax'
import { jStat } from 'jstat'
import { roundnks } from '../lib/math.js'

export default {
    name: 'zTestMM', 
    
    components: {
//      'vue-mathjax' : VueMathjax
    }, 

    data() {
        return {
            altHyp: 'Hneq', 
            s1mean: 55,
            s1psdev: 7,  
            s1n: 34, 
            s2mean: 59,
            s2psdev: 9, 
            s2n: 50, 
            alpha: 0.05,
            executed: null
      }
    },
  
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.executed = this.executeTest()
        }, 
        executeTest() {
            // NEUZUWEISUNGEN, UM ZAHLENWERTE ZU FORCIEREN
            var s1mean = Number(this.s1mean)
            var s1psdev = Number(this.s1psdev)
            var s1n = Number(this.s1n)
            var s2mean = Number(this.s2mean)
            var s2psdev = Number(this.s2psdev) 
            var s2n = Number(this.s2n)
            var alpha = Number(this.alpha)
            var stdErr = Math.sqrt( Math.pow(s1psdev,2) / s1n + Math.pow(s2psdev,2) / this.s2n)
            var cTestStat = (s2mean - s1mean)/stdErr
            var Hypothese, cCritDiff, cCritzValue, cpValue

            if(this.altHyp === 'Hlt') { // H1 < H2
                Hypothese = 'H₁ : &mu;₁ &lt; &mu;₂'
                
                cCritzValue =  - jStat.normal.inv( alpha, 0, 1)
                cCritDiff = cCritzValue * stdErr
                
                cpValue = 1 - jStat.normal.cdf(cTestStat, 0, 1)

            } 
            else if (this.altHyp === 'Hgt') { // H1 > H2
                Hypothese = 'H₁ : &mu;₁ &gt; &mu;₂'
                
                cCritzValue = jStat.normal.inv(alpha, 0, 1)
                cCritDiff = cCritzValue * stdErr
                
                cpValue = jStat.normal.cdf( cTestStat, 0, 1)
            } 
            else { // stat.thyp == 'Hneq' BEIDSEITIG
                Hypothese = 'H₁ : &mu;₁ &ne; &mu;₂'
                
                cTestStat = Math.abs(cTestStat)

                cCritzValue = jStat.normal.inv(1-alpha/2, 0, 1)
                cCritDiff = cCritzValue * stdErr

                cpValue = 2*jStat.normal.cdf( -cTestStat, 0, 1)
            }
            
            var entscheidung = ((cpValue < alpha) ? 1 : 0)

            return {
                'H1':                       Hypothese,              
                'alpha':                    alpha,
                'Stichprobe 1 Mittelwert':  s1mean,
                'Stichprobe 1 StdAbw':      s1psdev, 
                'Stichprobe 1 Umfang':      s1n, 
                'Stichprobe 2 Mittelwert':  s2mean,
                'Stichprobe 2 StdAbw':      s2psdev, 
                'Stichprobe 2 Umfang':      s2n, 
                'Teststatistik':            cTestStat, 
                'Standardfehler':           stdErr, 
                'p-Value':                  cpValue, 
                'kritischer z-Wert':        (this.altHyp === "Hneq" ? "±" : "" ) + roundnks(cCritzValue, 2),  
                'kritische Differenz':      cCritDiff, 
                'Entscheidung':             entscheidung,
                'H0variant':                ( entscheidung == 0 ? 'success' : 'danger' ),
                'H1variant':                ( entscheidung == 1 ? 'success'  : 'danger')
            }

        }        
    }

}
</script>

<template>
<div>
  <div class="header my-4">
    <h1>z-Tests und t-Tests</h1>
    <p>
        z-Test werden angewendet, wenn man Mittelwerte oder Anteilswerte einer Stichprobe gegen eine Population bzw. eine zweite Stichprobe testen möchte. 
        Dabei wird die <strong>Varianz als bekannt vorausgesetzt</strong>. <br />
        Ist die Varianz der Grundgesamtheit unbekannt, so testet man mit einem t-Test. Dieser übernimmt die Varianz aus der Stichprobe <span>(</span>empirische Varianz).   
    </p>
    <p>
        Da der z-Test die Standardfehler via Normalverteilung berechnet, ist er für kleine Stichproben <span>(</span>n &le; 30) ungeeignet. Hier ist die t-Verteilung präziser. 
    </p>
    <p>
        Hier wurden nur die z-Tests implementiert. Einen online-Statistikrechner für diverse Testarten finden sie bei <a href="https://datatab.de/statistik-rechner/hypothesentest">datatab</a>
    </p>
  </div>
  <div class="my-4">
    <h4>  welcher Test soll durchgeführt werden?  </h4>
    <b-form-select v-model="selected" >
      <option value="null" disabled>    Test auswählen                   </option>
            
      <option value="zm1">    z-Test: &mu;₁ vs Population        </option>
      <option value="zmm">    z-Test: &mu;₁ vs &mu;₂            </option>
      <option value="zp1">    z-Test: p₁ vs Population        </option>
      <option value="zpp">    z-Test: p₁ vs p₂            </option>
    </b-form-select>
      
  </div>

  <div class="section  my-4">

  <zTest-m1  v-if="(selected==='zm1')" />
  <zTest-mm  v-if="(selected==='zmm')" />
  <zTest-p1  v-if="(selected==='zp1')" />
  <zTest-pp  v-if="(selected==='zpp')" />

  </div>
</div>
</template>

<script>

import zTestM1 from '../components/zTest-M1.vue'
import zTestMM from '../components/zTest-MM.vue'
import zTestP1 from '../components/zTest-P1.vue'
import zTestPP from '../components/zTest-PP.vue'
// ohne Klammern, wenn "export default" importiert wird. 

export default {

    components: {
      'zTest-m1' : zTestM1,
      'zTest-mm' : zTestMM,
      'zTest-p1' : zTestP1,
      'zTest-pp' : zTestPP
    }, 

    data() {
      return {
        selected: null,
        options: [
          { value: null, text: 'Wählen sie einen Hypothesentest' }
        ]
      }
    }
  }
  </script>


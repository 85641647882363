<template>
<div>
    <!-- <h4>
        z-Test: Stichprobenmittelwert &mu;₁ vs Populationsmittelwert &mu;₀
    </h4> -->
    <b-form @submit="onSubmit">
    <b-card
        header="Testdaten"
        class="mb-2"
    >
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Hypothesen
            </b-card-sub-title>
            <b-form-row>
                <b-col cols = "4"> Nullhypothese       </b-col>
                <b-col cols = "8"> H₀ : &mu;₁ = &mu;₀  </b-col>
            </b-form-row>
            <b-form-row>
                <b-col cols = "4">
                    <label for="h1">Alternativhypothese</label>
                </b-col>
                <b-col cols = "8">
                    <b-form-select id="h1" v-model="altHyp" class="mb-2 mr-sm-2 mb-sm-0">
                        <option value="Hneq">H₁ : &mu;₁ &ne; &mu;₀ <span>(</span>beidseitig) </option>
                        <option value="Hlt">H₁ : &mu;₁ &lt; &mu;₀ 	<span>(</span>einseitig) </option>
                        <option value="Hgt">H₁ : &mu;₁ &gt; &mu;₀ <span>(</span>einseitig) </option>
                    </b-form-select>
                </b-col>
            </b-form-row>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Daten
            </b-card-sub-title>

            <b-form-group 
                        id="sample-data" 
                        label="Stichprobe:" 
                        description="in der Stichprobe erhobene Daten"
            >
                <label for="s-mean">Stichprobenmittelwert x&#x0304;
                    <b-form-input type="number" step="any" id="s-mean" v-model="smean"></b-form-input>
                </label>
                <label for="s-n">Stichprobenumfang n
                    <b-form-input type="number" step="any" id="s-n" v-model="sn"></b-form-input>
                </label>
            </b-form-group>

            <b-form-group
                id="population-data"
                label="Population:"
                description="über die Population bekannte Daten"
            >
                <label for="p-mean">&mu;₀
                    <b-form-input type="number" step="any" id="p-mean" v-model="pmean"></b-form-input>
                </label>
                <label for="p-stdev">&sigma;₀
                    <b-form-input type="number" step="any" id="p-stdev" v-model="pstdev"></b-form-input>
                </label>
            </b-form-group>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Testen
            </b-card-sub-title>
            <b-form-group
                id="test-data"
            >
                <label for="alpha"> Signifikanzniveau &alpha; 
                    <b-form-input type="number" step="any" id="alpha" v-model="alpha">&alpha;</b-form-input>
                </label>
                <b-button type="submit" variant="primary">Test ausführen</b-button>             
            </b-form-group>
        </b-card-body>    
    </b-card>
    </b-form>
    <b-card class="mt-3" header="Ergebnis des Tests" v-if="executed">
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Hypothesen
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item :variant="executed.H0variant">
                    H₀ : &mu;₁ = &mu;₀
                </b-list-group-item>
                <b-list-group-item :variant="executed.H1variant">
                    <span v-html="executed.H1"></span> <!-- Mustache geht nicht, weil HTML -->
                </b-list-group-item>
            </b-list-group>
            <b-card-text align-="center">
                <strong>
                Entscheidung für H{{executed.Entscheidung}}
                </strong>
            </b-card-text>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Ausgangslage
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item > Stichprobe Mittelwert: {{ executed['Stichprobe Mittelwert']}}</b-list-group-item>
                <b-list-group-item > Stichprobe Umfang: {{ executed['Stichprobe Umfang']}}</b-list-group-item>
                <b-list-group-item > Population Mittelwert: {{ executed['Population Mittelwert']}}</b-list-group-item>
                <b-list-group-item > Population StdAbw: {{ executed['Population Standardabw.']}}</b-list-group-item>
                <b-list-group-item > Signifikanzniveau: {{ executed.alpha }} </b-list-group-item>
            </b-list-group>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Testergebnis
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item > Standardfehler: {{ executed['Standardfehler']}}</b-list-group-item>
                <b-list-group-item > kritischer z-Wert: {{ executed['kritischer z-Wert']}}</b-list-group-item>
                <b-list-group-item > kritische Grenze: {{ executed['kritische Grenze']}}</b-list-group-item>
                <b-list-group-item > Teststatistik (z-Wert): {{ executed['Teststatistik'] }} </b-list-group-item>
                <b-list-group-item > p-Value: {{ executed['p-Value']}}</b-list-group-item>
                <b-list-group-item > Entscheidung: H{{ executed['Entscheidung']}}</b-list-group-item>
            </b-list-group>
        </b-card-body>

    </b-card>
</div>
</template>

<script>

// import { VueMathjax } from 'vue-mathjax'
import { jStat } from 'jstat'
import { roundnks } from '../lib/math.js'

export default {
    name: 'zTestM1', 
    
    components: {
//      'vue-mathjax' : VueMathjax
    }, 

    data() {
        return {
            altHyp: 'Hneq', 
            smean: 103, 
            sn: 100, 
            pmean: 100, 
            pstdev: 15, 
            alpha: 0.05,
            executed: null
      }
    },
  
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.executed = this.executeTest()
        }, 
        executeTest() {
            // NEUZUWEISUNGEN, UM ZAHLENWERTE ZU FORCIEREN
            // MITTELFRISTIG DURCH NATIVE v.model.number= BZW
            // bootstrap-vue-Alternative number="true" ersetzen. 
            var smean = Number(this.smean) 
            var sn = Number(this.sn)
            var pmean = Number(this.pmean)
            var pstdev = Number(this.pstdev)
            var alpha = Number(this.alpha)
            var stdErr = pstdev / Math.sqrt(sn)
            var cTestStat = (smean-pmean)/stdErr
            var Hypothese, cCritValue, cCritzValue, cpValue

            if(this.altHyp === 'Hlt') { // H1 < H0
                Hypothese = 'H₁ : &mu;₁ &lt; &mu;₀'
                
                cCritzValue =  jStat.normal.inv(alpha, 0, 1)
                cCritValue = pmean + cCritzValue * stdErr
                
                cpValue = jStat.normal.cdf(smean, pmean, stdErr)

            } 
            else if (this.altHyp === 'Hgt') { // H1 > H0
                Hypothese = 'H₁ : &mu;₁ &gt; &mu;₀'
                
                cCritzValue = jStat.normal.inv(1-alpha, 0, 1)
                cCritValue = pmean + cCritzValue * stdErr
                
                cpValue = 1-jStat.normal.cdf(smean, pmean, stdErr)
            } 
            else { // stat.thyp == 'Hneq' BEIDSEITIG
                Hypothese = 'H₁ : &mu;₁ &ne; &mu;₀'
                
                cCritzValue = jStat.normal.inv(1-alpha/2, 0, 1)
                cCritValue = roundnks(pmean - cCritzValue * stdErr, 2) + " und " + roundnks(pmean + cCritzValue * stdErr, 2) 
                cpValue = 2*jStat.normal.cdf( -Math.abs(cTestStat), 0, 1)

            }
            
            var entscheidung = ((cpValue < alpha) ? 1 : 0)

            return {
                'H1':                       Hypothese,              
                'alpha':                    alpha,
                'Stichprobe Mittelwert':    smean, 
                'Stichprobe Umfang':        sn, 
                'Population Mittelwert':    pmean,
                'Population Standardabw.':  pstdev,  
                'Teststatistik':            cTestStat, 
                'Standardfehler':           stdErr, 
                'p-Value':                  cpValue, 
                'kritischer z-Wert':        (this.altHyp === "Hneq" ? "±" : "" ) + roundnks(cCritzValue, 2),  
                'kritische Grenze':         cCritValue, 
                'Entscheidung':             entscheidung,
                'H0variant':                ( entscheidung == 0 ? 'success' : 'danger' ),
                'H1variant':                ( entscheidung == 1 ? 'success'  : 'danger')
            }

        }
    }

}
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Später lösen: eigene Theme-Anpassungen via scss-File
// Scheint aktuell ein Problem mit dem SASS-Loader zu geben. 
// import './styles/app.scss'

import VueAnalytics from 'vue-analytics'


Vue.use(BootstrapVue)

Vue.use(VueAnalytics, {
  id: '263066718', 
  router
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

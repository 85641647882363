<template>
<div>
  <div class="header my-4">
    <h1>Primzahlsieb</h1>
    <p>
      Primzahlen suchen bis bis 
      <b-form-input type="number" number="true" step="1" v-model="N">
      <!-- number = true ersetzt das native v-model.number. Klappt sonst nicht in eigenen Komponenten. -->
      </b-form-input>
    </p>
  </div>
  
  <div class="section">
    <b-button @click="run_self">selbständig</b-button> &nbsp;
    <b-button @click="hiddenMode = !hiddenMode">hide / black</b-button>
  </div>

  <div class="section">
    <h5>Neu gefundene Vielfache: {{ this.recent_changes }}</h5>
    
  </div>

  <div class="container">  
    <div v-for="number in numbers" v-bind:key="number.value"
        class="number"
        :class="{prime: number.isPrime, multiple: number.isMultiple}"
        v-show="!(hiddenMode && number.isMultiple)"
        @click="makePrime(number)" >
      {{  number.value }}
    </div>
  </div>
</div>
</template>

<script>


export default {
  name: 'App', 

  data () {
    return {
        numbers: [], 
        recent_changes: 0, 
        N : 1000, 
        hiddenMode: false 
    }
  },
  watch: {
    N:  function() {
      this.createNumbersArray()
    }
  }, 

  created() {
    this.createNumbersArray()
  }, 
  
  methods: {
    createNumbersArray() {
      this.numbers = []
      for (var i = 0; i < this.N; i++) {
        this.numbers.push(
          { "value": i,
            "isPrime": false, 
            "isMultiple": false
          }
        )
      }
      this.numbers[0].isMultiple = true
      this.numbers[1].isMultiple = true
    }, 
    makePrime(number) {
      console.log(number.value + "ist nächste Primzahl")
      number.isPrime = true
      this.recent_changes = 0
      for(var i = 2*number.value; i < this.N; i += number.value) {
        if(!this.numbers[i].isMultiple) { // wurde noch nicht markiert 
          this.numbers[i].isMultiple = true
          this.recent_changes++
        } 
      }

    }, 

    run_self() {
      this.recent_changes = 1 // nicht null!!!
      var first_free_number = 1
      
      while(this.recent_changes > 0) {
        while( this.numbers[first_free_number].isPrime ||  this.numbers[first_free_number].isMultiple ) {
          first_free_number++
        }
        this.makePrime(this.numbers[first_free_number])


      }    
    }

  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.container {
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;

}
.number {

    width: 40px;
    height: auto;
    border: 1px solid black;

    margin: 3px;
    background: #dcdcdc;
    border-radius: 3px;

    text-align: center;
    vertical-align: middle;
}

.prime {
    background: green;
    font-size: big;
    font-weight: bold;
}

.multiple {
    background: black;
    color: gray;
}



</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Euklid from '../views/Euklid.vue'
import Primzahlsieb from '../views/Primzahlsieb.vue'
import Hypothesentest from '../views/Hypothesentest.vue'
import Pythagoras from '../views/Pythagoras.vue'
import Vektorgeometrie from '../views/Vektorgeometrie.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/euklid',
    name: 'Euklid',
    component: Euklid
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }, 
  {
    path: '/primzahlsieb',
    name: 'Primzahlsieb',
    component: Primzahlsieb
  }, 
  {
    path: '/Hypothesentest',
    name: 'Hypothesentest',
    component: Hypothesentest
  }, 
  {
    path: '/Pythagoras',
    name: 'Pythagoras',
    component: Pythagoras
  }, 
  {
    path: '/Vektorgeometrie',
    name: 'Vektorgeometrie',
    component: Vektorgeometrie
  }, 
  
  
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
<div>
  <div class="header my-4">
    <h1>
      Pythagoras Karten
    </h1>
    <p class="description">
      Kartensammlung zum Pythagoras
    </p>
  </div>

  <div class="row my-1">
    <b-progress class="w-100" :value="n_solved" :max="50" show-value variant="success"></b-progress>
  </div>  

  <div class="row w-100">
    <b-button-group size="sm" class="mx-auto">
      <b-button @click="filter = '1'"><font-awesome-icon icon="tasks" /> 1</b-button>
      <b-button @click="filter = '2'"><font-awesome-icon icon="tasks" /> 2</b-button>
      <b-button @click="filter = '3'"><font-awesome-icon icon="tasks" /> 3</b-button>
      <b-button @click="filter = '4'"><font-awesome-icon icon="tasks" /> 4</b-button>
      <b-button @click="filter = '5'"><font-awesome-icon icon="tasks" /> 5</b-button>
      <b-button variant="success" @click="filter = 'done'"><font-awesome-icon icon="thumbs-up" /></b-button>
      <b-button variant="danger" @click="filter = 'undone'"><font-awesome-icon icon="thumbs-down" /></b-button>
    </b-button-group>
  </div>
  
  <div class="row row-cols-1"> <!-- nur 1 Karte pro Zeile -->

    <b-card class="w-100 my-1" no-body 
      v-for="aufgabe in filterlist" 
      :key="`aufgabe-${aufgabe.id}`"
      :border-variant = "aufgabe.done?'success':''"
    >
      <b-card-header class="text-center"
        :header-bg-variant = "aufgabe.done?'success':''"
        @click=" toggleCard(aufgabe) "
      >
        Aufgabe {{ aufgabe.id }}
      </b-card-header>
      <b-card-img 
        :src="aufgabe.img"
        v-if="aufgabe.showimg" 
        @click="switchCard(aufgabe)"
      >
      </b-card-img>

      <b-card-img 
        :src="aufgabe.sol" 
        v-if="aufgabe.showsol"
        @click="switchCard(aufgabe)"
      >
      </b-card-img>

      <b-card-img 
        
        @click="switchCard(aufgabe)"
      >
      </b-card-img>

      <b-card-footer 
        v-if="aufgabe.showimg || aufgabe.showsol"
        align="right"
      >
        <b-button pill 
          v-show="!aufgabe.done"
          variant="success"
          @click="done(aufgabe)"
        > 
          <font-awesome-icon icon="thumbs-up" />
        </b-button>
        <b-button pill 
          v-show="aufgabe.done"
          variant="danger"
          @click="undone(aufgabe)"
        > 
          <font-awesome-icon icon="thumbs-down" />
        </b-button>

      </b-card-footer>
      
        
    </b-card>
  </div>

</div>
</template>

<script>



import { library } from '@fortawesome/fontawesome-svg-core'
import { faThumbsUp, faThumbsDown, faTasks, faStarOfLife, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faThumbsUp)
library.add(faThumbsDown)
library.add(faStarOfLife)
library.add(faTasks)
library.add(faFilter)


import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('14d')

import { card_data } from "../lib/db.js"


export default {
  name: 'App',
  components: {
    'font-awesome-icon': FontAwesomeIcon
  }, 
  data: function() {

    const karten_array = []

    for(const card of card_data) {
      
      karten_array.push({
        id: card.id, 
        img: require('../assets/pythagoras/'+card.img),
        sol: require('../assets/pythagoras/'+card.sol),
        difficulty: card.difficulty, 
        text: card.text, 
        showimg: false,
        showsol: false, 
        done: this.$cookies.isKey(`aufgabe${card.id}`)
      })
    }

    return {
      filter: "1", 
      aufgabenkarten : karten_array                    
    }
  }, 
  methods: {
    toggleCard(aufgabe) {
      aufgabe.showsol = false
      aufgabe.showimg = !aufgabe.showimg

    }, 
    switchCard(aufgabe) {
      aufgabe.showimg = !aufgabe.showimg
      aufgabe.showsol = !aufgabe.showsol
    }, 
    done(aufgabe) {
      Vue.$cookies.set(`aufgabe${aufgabe.id}`, "done")
      aufgabe.done = true
    }, 
    undone(aufgabe) {
      Vue.$cookies.remove(`aufgabe${aufgabe.id}`, "done")
      aufgabe.done = false
    }

  }, 
  computed : {
    n_solved() {
      return this.aufgabenkarten.filter( card => {return card.done}).length
    }, 
    filterlist() { // "none" oder die genannten
      if(this.filter === "done") {
        return this.aufgabenkarten.filter( card => {return card.done} )
      } 
      else if(this.filter === "undone") {
        return this.aufgabenkarten.filter( card => {return !(card.done) } )
      }
      else if(this.filter === "1") {
        return this.aufgabenkarten.slice(0, 9)
      }
      else if(this.filter === "2") {
        return this.aufgabenkarten.slice(10, 19)
      }
      else if(this.filter === "3") {
        return this.aufgabenkarten.slice(20, 29)
      }
      else if(this.filter === "4") {
        return this.aufgabenkarten.slice(30, 39)
      }
      else if(this.filter === "5") {
        return this.aufgabenkarten.slice(40, 49)
      }
      else {
        return this.aufgabenkarten
      }

    }


  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

export { 
    Point, 
    Vector, 
    StraightLine, 
    Plane 
}

class Point {

    constructor(x, y, z) {
        this.x = x || 0
        this.y = y || 0
        this.z = z || 0
    }

    addVector(v) {
        return new Point(this.x + v.x, this.y + v.y, this.z + v.z)
    }
    toVector() {
        return new Vector(this.x, this.y, this.z)
    }

    distanceTo(p) {
        return this.vectorTo(p).size()
    }

    polar() {
        return {
            r: this.toVector().size(), 
            phi: Math.atan( this.y / this.x ), 
            theta: Math.atan( this.z / Math.sqrt(this.x*this.x + this.y+this.y))
        }
    }
    static vectorBetween(p, q) {
        return new Vector(q.x - p.x, q.y - p.y, q.z - p.z)
    }
}

class Vector {

    constructor(x, y, z) {
        this.x = x || 0
        this.y = y || 0
        this.z = z || 0
    }
    // Länge
    size() {
        return Math.sqrt( this.sizepow2() )
    }
    // Länge^2 
    sizepow2() {
        return Vector.dotP(this, this)
    }
    
    add(vec) {
        return new Vector(this.x+vec.x, this.y+vec.y, this.z+vec.z)
    }
    sub(vec) {
        return new Vector(this.x-vec.x, this.y-vec.y, this.z-vec.z)
    }
    
    mult(factor) {
        return new Vector(factor*this.x, factor*this.y, factor*this.z)
    }
    divide(divisor) {
        return this.factor(1/divisor)
    }

    invert() {
        return new Vector(-this.x, -this.y, -this.z)
    }

    equal(v) {
        return (this.x === v.x && this.y === v.y && this.z === v.z)
    }

    collinear(v) {
        if( this.isNullVector() ) {
            return true
        }
        
        const me = this.unit()
        const other = v.unit()
        return ( me.equal(other) || me.invert().equal(other) )
        
    }

    unit() {
        return this.mult( 1/this.size() )
    }

    isNullVector() {
        return this.x === 0 && this.y === 0 && this.z === 0
    }

    static vectorBetween(p, q) {
        return new Vector(q.x - p.x, q.y - p.y, q.z - p.z)
    }

    static dotP(u, v) {
        return u.x*v.x + u.y*v.y + u.z*v.z
    }

    static angleBetween(u, v) {
        if ( u.isNull(u) || v.isNull() ) {
            return undefined
        }

        const dot = Vector.dotP(u, v)
        const cosineValue = dot / u.size() / v.size()
        return Math.acos(cosineValue)
    }

    static crossP(u, v) {
        const x = u.y*v.z - u.z*v.y
        const y = u.z*v.x - u.x-v.z
        const z = u.x*v.y - u.y-v.x

        return new Vector(x, y, z) // hier "NEW" verwenden? 
    }
}

class StraightLine {

    constructor(p, r) {
        this.p = p
        this.r = r
    }
    
    contains(p) {
        return this.r.collinear( Vector.vectorBetween(this.p, p) )
    }

    parallel(g) {
        return this.r.collinear(g.r)
    }

    equals(g) {
        return this.contains(g.p) && this.parallel(g)
        // Ausarbeiten
    }

    apply(lambda) {
        return this.p.add( this.r.mult(lambda) )
    }

}


class Plane {
    // Intern wird die Ebene in !0-HNF gespeichert!
    // Konstruktor mit Normalenvektor und Punkt auf Ebene
    // Konstruktor mit Normalenvektor (a, b, c) und = d.
    constructor(n, p) {

        if( !(n instanceof Vector) || n.isNullVector() )
            throw new Error("Ebene undefiniert!")
        // ax + by +cz = d
        this.n = n.unit()

        if(typeof p === 'undefined' ) { // damit wird die Typreferenz, nicht die Variable selber gelesen
            this.d = 0
            console.log("p is undefined")
        }
        else if (p instanceof Point) {
            this.d = Vector.dotP(this.n, p.toVector())
        }
        else if (typeof p === 'number') {
            this.d = p / n.size()           // Weil HNF
            console.log(this.d, p, n.size())
        }
        else {
            console.log(p)
        }
    }

    static planeFromEquation(a, b, c, d) {
        const n = new Vector(a, b, c)
        const E = new Plane(n, d)
        return E
    }

    static planeFromPoints(p, q, r) {
        const n = Vector.crossP( Vector.vectorBetween(p, q), Vector.vectorBetween(p, r) )
        return new Plane(n, p)
    }

    equal(e) {
        if ( this.d === e.d ) {
            return this.n.equal(e.n)
        }
        else if ( this.d === -e.d ) {
            return this.n.equal( e.n.invert() )
        }
        return false
    }

    parallel(e) {
        return this.n === e.n || this.n === e.n.invert()
    }

    distanceTo(p) {
        return Vector.dotP(this.n, p.toVector()) - this.d
    }

    // Schnittpunkt
    intersect(g) {
        const lambda = ( this.d - Vector.dotP(this.n, g.r) ) / Vector.dotP(this.n, g.r)
        return g.apply(lambda)
    }
    // Lotfusspunkt
    lfp(p) {
        const g = new StraightLine(p, this.n)
        const lambda = ( this.d - Vector.dotP(this.n, p) ) // E in HNF
        return g.apply(lambda)
    }
    mirror(p) {
        const g = new StraightLine(p, this.n)
        const lambda = ( this.d - Vector.dotP(this.n, p) )
        return g.apply(2*lambda)
    }
    
}
<template>
<div>
  <div class="header my-4">
    <h1>
      Euklid'scher Algorithmus
    </h1>
    <p class="description">
      Berechnet den ggT zweier Zahlen ohne Faktorisierung
    </p>
  </div>
  
  <div class="form-group">
    <label for="a">erste Zahl
      <input id="a" v-model="a" />
    </label>
    <br />
    <label for="b">zweite Zahl
      <input id="b" v-model="b" />
    </label>
  </div>
  <div class="form-group">
    <button type="button" @click="euklid" >
        Berechne den ggT
    </button>
  </div>

  <div class="section">
    <b-list-group v-if="moduloliste">
      <b-list-group-item v-for="item in moduloliste" :key="item.id" >
          {{  item.dividend }} : {{ item.divisor }} = {{ item.quotient}} R {{item.reminder}}
      </b-list-group-item>
    </b-list-group>
  </div>

  <div class="section" v-if="ggt">
      <p>ergibt
      <b-list-group>
         <b-list-group-item> ggT( {{a}} , {{b}} ) = {{ format_number(ggt) }} </b-list-group-item>
         <b-list-group-item> kgV( {{a}} , {{b}} ) = {{ format_number(a*b/ggt) }} </b-list-group-item>
      </b-list-group>
      </p>
  </div>
</div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }, 
  data: function() {
    return {
      a: "", 
      b: "",
      moduloliste: [],
      ggt: null
    }
  }, 
  methods: {
    euklid() {
      this.moduloliste = []
      var divd
      var divr

      if(parseInt(this.a) > parseInt(this.b)) { // swap

        divd = parseInt(this.a)
        divr = parseInt(this.b)
      } else {
        divd = parseInt(this.b)
        divr = parseInt(this.a)
      }

      while(divr != 0) {
        var q = Math.floor(divd / divr)
        var r = divd % divr
        this.moduloliste.push({
          dividend: divd, 
          divisor: divr, 
          quotient:   q, 
          reminder:   r
        })

        divd = divr
        divr = r
      }

    this.ggt = divd

    }, 
    format_number(number) {
      var numeral = require("numeral");
      return numeral(number).format("'0,0'");
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
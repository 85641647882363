<template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand>
      <router-link to="/">
        <font-awesome-icon icon="igloo" />
      </router-link>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-2">
        <b-nav-item-dropdown text="Algebra" class="ml-2">
          <b-dropdown-item href="#">
            <router-link to="/euklid">Euklid'scher Algorithmus</router-link>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <router-link to="/primzahlsieb">Primzahlsieb</router-link>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        
        <b-nav-item-dropdown text="Geometrie" class="ml-2">
          <b-dropdown-item href="/Pythagoras">Pythagoras</b-dropdown-item>
        </b-nav-item-dropdown>
        
        <b-nav-item-dropdown text="Statistik" class="ml-2">
          <b-dropdown-item href="/Hypothesentest" >Tests</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="http://mmiu.ch/fachwerk">Fachwerk</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-2">
        <b-nav-item-dropdown right text="Diverses">
          <b-dropdown-item href="#">hot 'n' cold</b-dropdown-item>
          <b-dropdown-item href="http://mmiu.ch/covid-impf-sim/">Covid Impf Simulation</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import { faIgloo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(faIgloo)

export default {
  name: 'NavBar', 
  components: { 
    'font-awesome-icon': FontAwesomeIcon
  }, 

  data() {
      return {
      }
  },
  methods: {
  }
}    
</script>
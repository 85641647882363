<template>
<div>
    <!-- <h4>
        z-Test: Stichprobenmittelwert &mu;₁ vs Populationsmittelwert &mu;₀
    </h4> -->
    <b-form @submit="onSubmit">
    <b-card
        header="Testdaten"
        class="mb-2"
    >
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Hypothesen
            </b-card-sub-title>
            <b-form-row>
                <b-col cols = "4"> Nullhypothese       </b-col>
                <b-col cols = "8"> H₀ : p₁ = p₀  </b-col>
            </b-form-row>
            <b-form-row>
                <b-col cols = "4">
                    <label for="h1">Alternativhypothese</label>
                </b-col>
                <b-col cols = "8">
                    <b-form-select id="h1" v-model="altHyp" class="mb-2 mr-sm-2 mb-sm-0">
                        <option value="Hneq">H₁ : p₁ &ne; p₀ <span>(</span>beidseitig) </option>
                        <option value="Hlt">H₁ : p₁ &lt; p₀ 	<span>(</span>einseitig) </option>
                        <option value="Hgt">H₁ : p₁ &gt; p₀ <span>(</span>einseitig) </option>
                    </b-form-select>
                </b-col>
            </b-form-row>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Daten
            </b-card-sub-title>

            <b-form-group 
                        id="sample-data" 
                        label="Stichprobe:" 
                        description="in der Stichprobe erhobene Daten"
            >

                <label for="s-n">Stichprobenumfang n
                    <b-form-input type="number" step="any" id="s-n" v-model="sn"></b-form-input>
                </label>
                <label for="s-x">Stichprobentreffer x
                    <b-form-input type="number" step="any" id="s-x" v-model="sx"></b-form-input>
                </label>            
            </b-form-group>

            <b-form-group
                id="population-data"
                label="Population:"
                description="über die Population bekannte Daten"
            >
                <label for="p-p">p₀
                    <b-form-input type="number" step="any" id="p-p" v-model="pp"></b-form-input>
                </label>
            </b-form-group>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Testen
            </b-card-sub-title>
            <b-form-group
                id="test-data"
            >
                <label for="alpha"> Signifikanzniveau &alpha; 
                    <b-form-input type="number" step="any" id="alpha" v-model="alpha">&alpha;</b-form-input>
                </label>
                <b-button type="submit" variant="primary">Test ausführen</b-button>             
            </b-form-group>
        </b-card-body>    
    </b-card>
    </b-form>
    <b-card class="mt-3" header="Ergebnis des Tests" v-if="executed">
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Hypothesen
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item :variant="executed.H0variant">
                    H₀ : p₁ = p₀
                </b-list-group-item>
                <b-list-group-item :variant="executed.H1variant">
                    <span v-html="executed.H1"></span> <!-- Mustache geht nicht, weil HTML -->
                </b-list-group-item>
            </b-list-group>
            <b-card-text align-="center">
                <strong>
                Entscheidung für H{{executed.Entscheidung}}
                </strong>
            </b-card-text>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Ausgangslage
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item > Stichprobe Umfang: {{ executed['Stichprobe Umfang']}}</b-list-group-item>
                <b-list-group-item > Stichprobe Treffer: {{ executed['Stichprobe Treffer']}}</b-list-group-item>
                <b-list-group-item > Stichprobe Anteil: {{ format_percent(executed['Stichprobe Anteil']) }}</b-list-group-item>
                <b-list-group-item > Population Anteil: {{ format_percent(executed['Population Anteil']) }}</b-list-group-item>
                <b-list-group-item > Signifikanzniveau: {{ executed.alpha }} </b-list-group-item>
            </b-list-group>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Testergebnis
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item > Standardfehler: {{ format_percent(executed['Standardfehler']) }}</b-list-group-item>
                <b-list-group-item > kritischer z-Wert: {{ executed['kritischer z-Wert']}}</b-list-group-item>
                <b-list-group-item > kritischer Anteil: {{ executed['kritischer Anteil']}}</b-list-group-item>
                <b-list-group-item > Teststatistik (z-Wert): {{ executed['Teststatistik'] }} </b-list-group-item>
                <b-list-group-item > p-Value: {{ executed['p-Value']}}</b-list-group-item>
                <b-list-group-item > Entscheidung: H{{ executed['Entscheidung']}}</b-list-group-item>
            </b-list-group>
        </b-card-body>

    </b-card>
</div>
</template>

<script>

// import { VueMathjax } from 'vue-mathjax'
import { jStat } from 'jstat'
import { roundnks, format_percent } from '../lib/math.js'

export default {
    name: 'zTestP1', 
    
    components: {
//      'vue-mathjax' : VueMathjax
    }, 

    data() {
        return {
            altHyp: 'Hneq', 
            sn: 200, 
            sx: 15, 
            pp: .11, 
            alpha: 0.05,
            executed: null
      }
    },
  
    methods: {
        format_percent: format_percent, 
        onSubmit(event) {
            event.preventDefault();
            this.executed = this.executeTest()
        }, 
        executeTest() {
            // NEUZUWEISUNGEN, UM ZAHLENWERTE ZU FORCIEREN
            var sn = Number(this.sn) 
            var sx = Number(this.sx) 
            var pp = Number(this.pp) 
            var alpha = Number(this.alpha)

            var sp = sx / sn
            var stdErr = Math.sqrt( pp*(1-pp)/sn )
            var cTestStat = (sp-pp)/stdErr
            var Hypothese, cCritAnteil, cCritzValue, cpValue

            if(this.altHyp === 'Hlt') { // P1 < P0
                Hypothese = 'H₁ : p₁ &lt; p₀'
                
                cCritzValue =  jStat.normal.inv(alpha, 0, 1)
                cCritAnteil = roundnks( sn * (pp + cCritzValue * stdErr) , 1)
                
                cpValue = jStat.normal.cdf(cTestStat, 0, 1)
            } 
            else if (this.altHyp === 'Hgt') { // P1 > P0
                Hypothese = 'H₁ : p₁ &gt; p₀'
                
                cCritzValue = jStat.normal.inv(1 - alpha, 0, 1)
                cCritAnteil = roundnks( sn * (pp + cCritzValue * stdErr) , 1)
                
                cpValue = 1-jStat.normal.cdf(cTestStat, 0, 1)
            } 
            else { // stat.thyp == 'Hneq' BEIDSEITIG
                Hypothese = 'H₁ : p₁ &ne; p₀'
                
                cCritzValue = jStat.normal.inv(1 - alpha/2, 0, 1)
                cTestStat = Math.abs(cTestStat)

                cCritAnteil = roundnks(sn * (pp - cCritzValue * stdErr) , 1) 
                    + " bis " 
                    + roundnks( sn * (pp + cCritzValue * stdErr), 1)

                cpValue = 2*jStat.normal.cdf( -cTestStat, 0, 1)
            }
            
            var entscheidung = ((cpValue < alpha) ? 1 : 0)

            return {
                'H1':                       Hypothese,              
                'alpha':                    alpha,
                'Stichprobe Umfang':        sn,
                'Stichprobe Treffer':       sx,  
                'Stichprobe Anteil':        sp,
                'Population Anteil':        pp,  
                'Teststatistik':            cTestStat, 
                'Standardfehler':           stdErr, 
                'p-Value':                  cpValue, 
                'kritischer z-Wert':        (this.altHyp === "Hneq" ? "±" : "" ) + roundnks(cCritzValue, 2),  
                'kritischer Anteil':        cCritAnteil, 
                'Entscheidung':             entscheidung,
                'H0variant':                ( entscheidung == 0 ? 'success' : 'danger' ),
                'H1variant':                ( entscheidung == 1 ? 'success'  : 'danger')
            }

        }       
    }

}
</script>

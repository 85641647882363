<template>
<div>
  <!-- NOCH NICHT IMPLEMENTIERT -->
    <div class="header my-4">
      <h1> Punkt / Ebene / Gerade </h1>
      <p class="description"> Berechnet den Lotfusspunkt, den Spiegelpunkt sowie den Abstand eines Punktes zu einer gegebenen Ebene </p>
    </div>

    <div class="main my-4">
      <b-form>
        <b-card header="Eingabedaten" class="mb-2">
          <b-card-body>

            <!-- Punkt --> 
            <b-form-group
              id="read_point_form"
              label="Punkt"
              label-align
              description="P(x,y,z)"
              label-for="read_point_inputs"     >
              <b-input-group 
                id="read_point_inputs"
                size="sm" prepend="P ( ">
                <b-form-input type="number" step="any" v-model="P.x"></b-form-input>
                <b-input-group-append is-text><b>,</b></b-input-group-append>
                <b-form-input type="number" step="any" v-model="P.y"></b-form-input>
                <b-input-group-append is-text><b>,</b></b-input-group-append>
                <b-form-input type="number" step="any" v-model="P.z"></b-form-input>
                <b-input-group-append is-text><b>)</b></b-input-group-append>
              </b-input-group>
          </b-form-group>

          <!-- Richtungsvektor --> 
          <b-form-group
              id="read_r_form"
              label="Richtungsvektor"
              label-align
              description="r = (x,y,z)"
              label-for="input_r_inputs"    >
            <b-input-group 
              id="read_r_form"
              size="sm" prepend="r = ( "      >
              
                <b-form-input type="number" step="any" v-model="r.x"></b-form-input>
                <b-input-group-append is-text><b>,</b></b-input-group-append>
                <b-form-input type="number" step="any" v-model="r.y"></b-form-input>
                <b-input-group-append is-text><b>,</b></b-input-group-append>
                <b-form-input type="number" step="any" v-model="r.z"></b-form-input>
                <b-input-group-append is-text><b>)</b></b-input-group-append>
            </b-input-group>
          </b-form-group>
          <!-- Ebene -->
          <b-form-group
            id="read_plane_form"
            label="Ebenengleichung"
            label-align
            description="E: ax+by+cz = d"
            label-for="input_plane_inputs"
          >
            <b-input-group 
              size="sm" prepend="E : ">
              
              <b-form-input class="col-sm-4" step="any" type="number" v-model="E.a"></b-form-input>
              <b-input-group-append is-text><b>x</b></b-input-group-append>
              
              <b-input-group-prepend is-text><b>+</b></b-input-group-prepend>
              <b-form-input type="number" step="any" v-model="E.b"></b-form-input>
              <b-input-group-append is-text><b>y</b></b-input-group-append>
              
              <b-input-group-prepend is-text><b>+</b></b-input-group-prepend>
              <b-form-input type="number" step="any" v-model="E.c"></b-form-input>
              <b-input-group-append is-text><b>z</b></b-input-group-append>
              
              <b-input-group-prepend is-text><b>=</b></b-input-group-prepend>
              <b-form-input type="number" step="any" v-model="E.d"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-card-body>

        <hr />
        <!-- ACTIONS -->
        <b-card-body>
          <b-button-toolbar key-nav>
            <b-button-group>
              <b-button
                @click="exec_pe"
                class="mx-1">
                  Punkt/Ebene
              </b-button>
              <b-button
                @click="exec_ge"
                class="mx-1">
                  Gerade/Ebene
              </b-button>
            </b-button-group>
          </b-button-toolbar>

        </b-card-body>
      </b-card>
    </b-form>
  </div>
</div>
</template>

<script>

import {Vector, StraightLine, Point, Plane } from "../lib/vektorgeometrie.js"

export default {
  name: 'Home',
  components: {
  }, 
  data: function() {
    return {
      E: { a: 0, b: 0, c: 0, d: 0 }, 
      P: { x: 0, y: 0, z: 0 }, 
      r: { x: 0, y: 0, z: 0 }
    }
  },

  methods: {
    exec_ge: function() {
      const E = new Plane(new Vector(this.E.a, this.E.b, this.E.c), Number(this.E.d))
      const P = new Point(this.P.x, this.P.y, this.P.z)
      const r = new Vector(this.r.x, this.r.y, this.r.z)
      const g = new StraightLine(P, r)
      console.log(E)
      console.log(g)
    }, 
    exec_pe: function() {

    }
  }
}
</script>

<style>
.footer {
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}
</style>
<template>
<div>
    <!-- <h4>
        z-Test: Stichprobenmittelwert &mu;₁ vs Populationsmittelwert &mu;₀
    </h4> -->
    <b-form @submit="onSubmit">
    <b-card
        header="Testdaten"
        class="mb-2"
    >
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Hypothesen
            </b-card-sub-title>
            <b-form-row>
                <b-col cols = "4"> Nullhypothese       </b-col>
                <b-col cols = "8"> H₀ : p₁ = p₂  </b-col>
            </b-form-row>
            <b-form-row>
                <b-col cols = "4">
                    <label for="h1">Alternativhypothese</label>
                </b-col>
                <b-col cols = "8">
                    <b-form-select id="h1" v-model="altHyp" class="mb-2 mr-sm-2 mb-sm-0">
                        <option value="Hneq">H₁ : p₁ &ne; p₂ <span>(</span>beidseitig) </option>
                        <option value="Hlt">H₁ : p₁ &lt; p₂ 	<span>(</span>einseitig) </option>
                        <option value="Hgt">H₁ : p₁ &gt; p₂ <span>(</span>einseitig) </option>
                    </b-form-select>
                </b-col>
            </b-form-row>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Daten
            </b-card-sub-title>

            <b-form-group 
                        id="sample1-data" 
                        label="Stichprobe 1:" 
                        description="in der 1. Stichprobe erhobene Daten"
            >

                <label for="s1-n">Stichprobenumfang n₁
                    <b-form-input type="number" step="any" id="s1-n" v-model="s1n"></b-form-input>
                </label>
                <label for="s1-x">Stichprobentreffer x₁
                    <b-form-input type="number" step="any" id="s1-x" v-model="s1x"></b-form-input>
                </label>            
            </b-form-group>

            <b-form-group
                        id="sample2-data" 
                        label="Stichprobe 2:" 
                        description="in der 2. Stichprobe erhobene Daten"
            >
                <label for="s2-n">Stichprobenumfang n₂
                    <b-form-input type="number" step="any" id="s2-n" v-model="s2n"></b-form-input>
                </label>
                <label for="s2-x">Stichprobentreffer x₂
                    <b-form-input type="number" step="any" id="s2-x" v-model="s2x"></b-form-input>
                </label>            
            </b-form-group>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Testen
            </b-card-sub-title>
            <b-form-group
                id="test-data"
            >
                <label for="alpha"> Signifikanzniveau &alpha; 
                    <b-form-input type="number" step="any" id="alpha" v-model="alpha">&alpha;</b-form-input>
                </label>
                <b-button type="submit" variant="primary">Test ausführen</b-button>             
            </b-form-group>
        </b-card-body>    
    </b-card>
    </b-form>
    <b-card class="mt-3" header="Ergebnis des Tests" v-if="executed">
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Hypothesen
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item :variant="executed.H0variant">
                    H₀ : p₁ = p₂
                </b-list-group-item>
                <b-list-group-item :variant="executed.H1variant">
                    <span v-html="executed.H1"></span> <!-- Mustache geht nicht, weil HTML -->
                </b-list-group-item>
            </b-list-group>
            <b-card-text align-="center">
                <strong>
                Entscheidung für H{{executed.Entscheidung}}
                </strong>
            </b-card-text>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Ausgangslage
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item variant="dark"> Stichprobe 1</b-list-group-item>
                <b-list-group-item > Umfang: {{ executed['Stichprobe1 Umfang']}}</b-list-group-item>
                <b-list-group-item > Treffer: {{ executed['Stichprobe1 Treffer']}}</b-list-group-item>
                <b-list-group-item > Anteil: {{ format_percent(executed['Stichprobe1 Anteil']) }}</b-list-group-item>
                <b-list-group-item variant="dark"> Stichprobe 2</b-list-group-item>
                <b-list-group-item > Umfang: {{ executed['Stichprobe2 Umfang']}}</b-list-group-item>
                <b-list-group-item > Treffer: {{ executed['Stichprobe2 Treffer']}}</b-list-group-item>
                <b-list-group-item > Anteil: {{ format_percent(executed['Stichprobe2 Anteil']) }}</b-list-group-item>
                <b-list-group-item variant="dark"> Vergleich der Proben</b-list-group-item>
                <b-list-group-item > Gesamt Anteil: {{ format_percent(executed['Gesamt Anteil']) }}</b-list-group-item>                
                <b-list-group-item > Differenz: {{ format_percent(executed['Differenz']) }}</b-list-group-item>
                <b-list-group-item > Signifikanzniveau: {{ executed.alpha }} </b-list-group-item>
            </b-list-group>
        </b-card-body>
        <b-card-body>
            <b-card-sub-title class="mb-2">
                Testergebnis
            </b-card-sub-title>
            <b-list-group>
                <b-list-group-item > Standardfehler: {{ format_percent(executed['Standardfehler']) }}</b-list-group-item>
                <b-list-group-item > kritischer z-Wert: {{ executed['kritischer z-Wert']}}</b-list-group-item>
                <b-list-group-item > Teststatistik (z-Wert): {{ executed['Teststatistik'] }} </b-list-group-item>
                <b-list-group-item > p-Value: {{ executed['p-Value']}}</b-list-group-item>
                <b-list-group-item > Entscheidung: H{{ executed['Entscheidung']}}</b-list-group-item>
            </b-list-group>
        </b-card-body>

    </b-card>
</div>
</template>

<script>

// import { VueMathjax } from 'vue-mathjax'
import { jStat } from 'jstat'
import { roundnks, format_percent } from '../lib/math.js'

export default {
    name: 'zTestP1', 
    
    components: {
//      'vue-mathjax' : VueMathjax
    }, 

    data() {
        return {
            altHyp: 'Hneq', 
            s1n: 374, 
            s1x: 26, 
            s2n: 210, 
            s2x: 8, 
            alpha: 0.05,
            executed: null
      }
    },
  
    methods: {
        format_percent: format_percent, 
        
        onSubmit(event) {
            event.preventDefault();
            this.executed = this.executeTest()
        }, 
        executeTest() {
            // NEUZUWEISUNGEN, UM ZAHLENWERTE ZU FORCIEREN
            var s1n = Number(this.s1n) 
            var s1x = Number(this.s1x) 
            var s2n = Number(this.s2n) 
            var s2x = Number(this.s2x)
            var alpha = Number(this.alpha)
            var s1p = s1x / s1n
            var s2p = s2x / s2n
            var pp = (s1x + s2x) / (s1n + s2n) // gemittelter Anteil aus der ganzen Erhebung
            
            var stdErr = Math.sqrt( pp*(1 - pp) * (1/s1n + 1/s2n ) ) // SQRT ( sigma^2 / n1 + sigma^2 / n1 )
            var cTestStat = (s1p - s2p)/stdErr

            var Hypothese, cCritzValue, cpValue

            if(this.altHyp === 'Hlt') { // P1 < P2
                Hypothese = 'H₁ : p₁ &lt; p₂'
                
                cCritzValue =  jStat.normal.inv(alpha, 0, 1)
                
                cpValue = jStat.normal.cdf(cTestStat, 0, 1)
            } 
            else if (this.altHyp === 'Hgt') { // P1 > P2
                Hypothese = 'H₁ : p₁ &gt; p₂'
                
                cCritzValue = jStat.normal.inv(1-alpha, 0, 1)
                
                cpValue = 1-jStat.normal.cdf(cTestStat, 0, 1)
            } 
            else { // stat.thyp == 'Hneq' BEIDSEITIG
                Hypothese = 'H₁ : p₁ &ne; p₂'
                
                cCritzValue = jStat.normal.inv(1-alpha/2, 0, 1)
                cTestStat = Math.abs(cTestStat)

                cpValue = 2*jStat.normal.cdf( -cTestStat, 0, 1)
            }
            
            var entscheidung = ((cpValue < alpha) ? 1 : 0)

            return {
                'H1':                       Hypothese,              
                'alpha':                     alpha,
                'Stichprobe1 Umfang':        s1n,
                'Stichprobe1 Treffer':       s1x,  
                'Stichprobe1 Anteil':        s1p,
                'Stichprobe2 Umfang':        s2n,
                'Stichprobe2 Treffer':       s2x,  
                'Stichprobe2 Anteil':        s2p,
                'Gesamt Anteil':             pp,
                'Differenz':                Math.abs(s1p - s2p), 
                'Teststatistik':            cTestStat, 
                'Standardfehler':           stdErr, 
                'p-Value':                  cpValue, 
                'kritischer z-Wert':        (this.altHyp === "Hneq" ? "±" : "" ) + roundnks(cCritzValue, 2),  
                'Entscheidung':             entscheidung,
                'H0variant':                ( entscheidung == 0 ? 'success' : 'danger' ),
                'H1variant':                ( entscheidung == 1 ? 'success'  : 'danger')
            }

        }
    }

}
</script>

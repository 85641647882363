<template>
<div>
    <div class="header my-4">
      <h1> math.mmiu </h1>
      <p class="description">Auf math.mmiu finden sich eine Reihe mathematischer Tools, Apps, Links und Spiele für meinen Unterricht. </p>

    </div>

    <div class="main my-4">

      <h5>intern</h5>
      <b-list-group>
        <b-list-group-item href="http://mmiu.ch/fachwerk">Fachwerk</b-list-group-item>
        <b-list-group-item href="https://app.lernnavi.ch/login">Lernnavi</b-list-group-item>
        <b-list-group-item href="https://matraxi.ch">matraxi</b-list-group-item>
      </b-list-group>
      <h5>extern</h5>
      <b-list-group>
        <b-list-group-item href="https://www.desmos.com/calculator?lang=de">Desmos</b-list-group-item>
        <b-list-group-item href="https://www.geogebra.org/">Geogebra</b-list-group-item>
        <b-list-group-item href="https://www.wolframalpha.com/">Wolfram Alpha</b-list-group-item>
        <b-list-group-item href="https://de.khanacademy.org/">Khan Academy</b-list-group-item>    
        <b-list-group-item href="https://brilliant.org/">Brilliant</b-list-group-item>    
        <b-list-group-item href="https://mathlive.io/">MathLive</b-list-group-item>      
      </b-list-group>
    </div>

    <footer class="footer">
      <div class="container">
        <p class="text-muted">
        Klicken Sie <a href="#" @click.prevent="disableTracking">hier</a>,
        um das Tracking durch Google Analytics zu deaktivieren.
      </p>
    </div>
    </footer>
</div>
</template>

<script>


export default {
  name: 'Home',
  components: {
  }, 
  methods: {
    disableTracking: function() {
      this.$ga.disable();
      alert('Tracking disabled');
    }
  }
}
</script>

<style>
.footer {
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}
</style>
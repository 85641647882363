export const card_data = [
    {id: '01', img: '01.png', sol: 'L01.png', difficulty: 1, text: 'Rechtwinklig?'}, 
    {id: '02', img: '02.png', sol: 'L02.png', difficulty: 1, text: 'Rechtwinklig?'}, 
    {id: '03', img: '03.png', sol: 'L03.png', difficulty: 2, text: 'Velofahrer'}, 
    {id: '04', img: '04.png', sol: 'L04.png', difficulty: 2, text: 'Segelboot'}, 
    {id: '05', img: '05.png', sol: 'L05.png', difficulty: 1, text: 'Dreiecke'}, 
    {id: '06', img: '06.png', sol: 'L06.png', difficulty: 2, text: 'Koordinaten'}, 
    {id: '07', img: '07.png', sol: 'L07.png', difficulty: 2, text: 'Koordinaten'}, 
    {id: '08', img: '08.png', sol: 'L08.png', difficulty: 2, text: 'Koordinaten'}, 
    {id: '09', img: '09.png', sol: 'L09.png', difficulty: 1, text: '16 Punkte'}, 
    {id: '10', img: '10.png', sol: 'L10.png', difficulty: 1.5, text: 'Trapez'}, 
    {id: '11', img: '11.png', sol: 'L11.png', difficulty: 2, text: 'Wasser im Rohr'}, 
    {id: '12', img: '12.png', sol: 'L12.png', difficulty: 2, text: 'Sechseck'}, 
    {id: '13', img: '13.png', sol: 'L13.png', difficulty: 2, text: 'Velofahrer am Berg'}, 
    {id: '14', img: '14.png', sol: 'L14.png', difficulty: 3.5, text: 'Brücke'}, 
    {id: '15', img: '15.png', sol: 'L15.png', difficulty: 2, text: 'Böschung'}, 
    {id: '16', img: '16.png', sol: 'L16.png', difficulty: 3, text: 'Baumstamm'}, 
    {id: '17', img: '17.png', sol: 'L17.png', difficulty: 2, text: 'Eisenbahn'}, 
    {id: '18', img: '18.png', sol: 'L18.png', difficulty: 1, text: 'Baumstamm'}, 
    {id: '19', img: '19.png', sol: 'L19.png', difficulty: 2, text: 'Seilbahn'}, 
    {id: '20', img: '20.png', sol: 'L20.png', difficulty: 4, text: 'Gleichseitiges Dreieck'}, 
    {id: '21', img: '21.png', sol: 'L21.png', difficulty: 3, text: 'Teich'}, 
    {id: '22', img: '22.png', sol: 'L22.png', difficulty: 1, text: 'Dachdecker'}, 
    {id: '23', img: '23.png', sol: 'L23.png', difficulty: 1.5, text: 'Ackerfläche'}, 
    {id: '24', img: '24.png', sol: 'L24.png', difficulty: 1.5, text: 'Quadrat und Kreis'}, 
    {id: '25', img: '25.png', sol: 'L25.png', difficulty: 2, text: 'Umkreis'}, 
    {id: '26', img: '26.png', sol: 'L26.png', difficulty: 1, text: 'Ballon'}, 
    {id: '27', img: '27.png', sol: 'L27.png', difficulty: 1, text: 'Rechzeck und Kreis'}, 
    {id: '28', img: '28.png', sol: 'L28.png', difficulty: 1, text: 'Rappie McUnzel'}, 
    {id: '29', img: '29.png', sol: 'L29.png', difficulty: 2, text: 'Koordinatensystem'}, 
    {id: '30', img: '30.png', sol: 'L30.png', difficulty: 2, text: 'Pyramide'}, 
    {id: '31', img: '31.png', sol: 'L31.png', difficulty: 1.5, text: 'Raumdiagonalen'}, 
    {id: '32', img: '32.png', sol: 'L32.png', difficulty: 3.5, text: 'Achteck'}, 
    {id: '33', img: '33.png', sol: 'L33.png', difficulty: 2.5, text: 'Flugzeug'}, 
    {id: '34', img: '34.png', sol: 'L34.png', difficulty: 2.5, text: 'Mondlandung'}, 
    {id: '35', img: '35.png', sol: 'L35.png', difficulty: 1, text: 'Xavers schräge Mauer'}, 
    {id: '36', img: '36.png', sol: 'L36.png', difficulty: 3, text: 'Glocke'}, 
    {id: '37', img: '37.png', sol: 'L37.png', difficulty: 1, text: 'Kegeldach'}, 
    {id: '38', img: '38.png', sol: 'L38.png', difficulty: 1, text: 'Bild'}, 
    {id: '39', img: '39.png', sol: 'L39.png', difficulty: 3, text: 'Mastbruch'}, 
    {id: '40', img: '40.png', sol: 'L40.png', difficulty: 1, text: 'Taucher'}, 
    {id: '41', img: '41.png', sol: 'L41.png', difficulty: 1.5, text: 'ZocaDola'}, 
    {id: '42', img: '42.png', sol: 'L42.png', difficulty: 1.5, text: 'Oktaeder'}, 
    {id: '43', img: '43.png', sol: 'L43.png', difficulty: 2.5, text: 'Trapez'}, 
    {id: '44', img: '44.png', sol: 'L44.png', difficulty: 2, text: 'Parallelogramm'}, 
    {id: '45', img: '45.png', sol: 'L45.png', difficulty: 1.5, text: 'Bergspitzen'}, 
    {id: '46', img: '46.png', sol: 'L46.png', difficulty: 1, text: 'Schwimmen'}, 
    {id: '47', img: '47.png', sol: 'L47.png', difficulty: 1, text: 'Sturmschaden'}, 
    {id: '48', img: '48.png', sol: 'L48.png', difficulty: 1, text: 'Fernseher'}, 
    {id: '49', img: '49.png', sol: 'L49.png', difficulty: 1, text: 'Spanplatte'}, 
    {id: '50', img: '50.png', sol: 'L50.png', difficulty: 1.5, text: 'Zaum um Grundstück'} 
]
